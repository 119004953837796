<template>
  <section class="section">
    <nav class="level is-vcentered">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">Cambiar Contraseña</h1>
        </div>
      </div>
    </nav>
    <div class="inner-section">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-6 box">
            <div class="columns is-multiline">
              <div class="column is-12">
                <label class="label">Contraseña Actual</label>
                <div class="field">
                  <div class="control has-icons-left">
                    <input
                      ref="currentPassword"
                      class="input is-rounded"
                      type="password"
                      v-model="currentPassword"
                      name="currentPassword"
                      placeholder="Contraseña Actual"
                      v-validate="'required'"
                      data-vv-as="contraseña actual"
                    >
                    <span class="icon is-small is-left">
                      <img class="input_icon_pass" src="@/assets/icon/lock-icon.png" alt="password">
                    </span>
                  </div>
                </div>
                <span v-if="vverrors.has('currentPassword')" class="help is-danger">{{ vverrors.first('currentPassword') }}</span>
              </div>
              <div class="column is-12">
                <label class="label">Nueva Contraseña</label>
                <div class="field">
                  <div class="control has-icons-left">
                    <input
                      ref="password"
                      class="input is-rounded"
                      type="password"
                      v-model="password"
                      name="password"
                      placeholder="Contraseña"
                      v-validate="'required'"
                      data-vv-as="contraseña"
                    >
                    <span class="icon is-small is-left">
                      <img class="input_icon_pass" src="@/assets/icon/lock-icon.png" alt="password">
                    </span>
                  </div>
                </div>
                <span v-if="vverrors.has('password')" class="help is-danger">{{ vverrors.first('password') }}</span>
              </div>
              <div class="column is-12">
                <label class="label">Confirmar Contraseña</label>
                <div class="field">
                  <div class="control has-icons-left">
                    <input
                      class="input is-rounded"
                      :class="{'is-danger': vverrors.has('password_confirmation')}"
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirmar Contraseña"
                      data-vv-as="confirmar contraseña"
                      v-validate="'required|confirmed:password'"
                      v-model="passwordConfirmation"
                    >
                    <span class="icon is-small is-left">
                      <img class="input_icon_pass" src="@/assets/icon/lock-icon.png" alt="password">
                    </span>
                  </div>
                </div>
                <span v-if="vverrors.has('password_confirmation')" class="help is-danger">{{ vverrors.first('password_confirmation') }}</span>
              </div>
              <div class="column is-12 has-text-right">
                <button
                  class="button button_ibs is-rounded"
                  :class="{ 'is-loading': loading }"
                  @click="changePasswordAction"
                >
                  Cambiar Contraseña
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ChangePasswordView',
  data () {
    return {
      password: null,
      currentPassword: null,
      passwordConfirmation: null,
      loading: false
    }
  },
  methods: {
    ...mapActions(['changePassword']),
    async changePasswordAction () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          password: this.password,
          currentPassword: this.currentPassword
        }
        const resp = await this.changePassword(data)
        if (resp) {
          this.password = null
          this.currentPassword = null
          this.passwordConfirmation = null
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .section {
    width: 100%;
    min-height: calc(100vh - 90px);
    padding: 0;
  }

  .inner-section {
    display: flex;
    justify-content: center;
  }

  .box {
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e1e5eb;
    background-color: #ffffff;
    padding: 1.5rem;
  }

  .form_title {
    font-size: 18px;
    font-weight: bold;
    color: #404254;
  }
  .input_icon {
    width: 16px;
  }
  .input_icon_pass {
    width: 12px;
  }

  .form_forgot_text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: #404254;
  }
  .form_forgot_text:hover {
    color: #33343d;
  }
</style>
